// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        authenticated: false,
        adminUser: false,
        currentUserId: null,
        currentOrganizationId: null,
        userOrganizationId: null,
        currentProjectId: null,
        projectOrgId: null,
    },
    reducers: {
        setAuthenticated: (state, action) => { state.authenticated = action.payload; },
        setAdminUser: (state, action) => { state.adminUser = action.payload; },
        setCurrentUserId: (state, action) => { state.currentUserId = action.payload; },
        setCurrentOrgId: (state, action) => { state.currentOrganizationId = action.payload; },
        setUserOrgId:(state, action) =>    { state.userOrganizationId = action.payload; },
        setCurrentProjectId: (state, action) => { state.currentProjectId = action.payload; },
        setProjectOrgId: (state, action) => { state.projectOrgId = action.payload; },
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(userAsyncThunk.fulfilled, (state, action) => {
    //             state.loading = 'idle';
    //             state.entities = action.payload;
    //         })
    //         .addCase(fetchDataThunk.rejected, (state, action) => {
    //             state.loading = 'idle';
    //             state.error = action.error.message;
    //         });
    // },
});

export const { setAuthenticated, setAdminUser, setCurrentUserId,
    setCurrentOrgId, setUserOrgId, setCurrentProjectId, setProjectOrgId } = userSlice.actions;
export default userSlice.reducer;