/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  SwitchField,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Project } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function ProjectCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    name: "",
    description: "",
    userInitiated: false,
    base_prompt_intro: "",
    summary_template: "",
    base_prompt: "",
    initial_answer_prompt: "",
    summary_prompt: "",
    currentSummary: "",
  };
  const [name, setName] = React.useState(initialValues.name);
  const [description, setDescription] = React.useState(
    initialValues.description
  );
  const [userInitiated, setUserInitiated] = React.useState(
    initialValues.userInitiated
  );
  const [base_prompt_intro, setBase_prompt_intro] = React.useState(
    initialValues.base_prompt_intro
  );
  const [summary_template, setSummary_template] = React.useState(
    initialValues.summary_template
  );
  const [base_prompt, setBase_prompt] = React.useState(
    initialValues.base_prompt
  );
  const [initial_answer_prompt, setInitial_answer_prompt] = React.useState(
    initialValues.initial_answer_prompt
  );
  const [summary_prompt, setSummary_prompt] = React.useState(
    initialValues.summary_prompt
  );
  const [currentSummary, setCurrentSummary] = React.useState(
    initialValues.currentSummary
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setName(initialValues.name);
    setDescription(initialValues.description);
    setUserInitiated(initialValues.userInitiated);
    setBase_prompt_intro(initialValues.base_prompt_intro);
    setSummary_template(initialValues.summary_template);
    setBase_prompt(initialValues.base_prompt);
    setInitial_answer_prompt(initialValues.initial_answer_prompt);
    setSummary_prompt(initialValues.summary_prompt);
    setCurrentSummary(initialValues.currentSummary);
    setErrors({});
  };
  const validations = {
    name: [],
    description: [],
    userInitiated: [],
    base_prompt_intro: [],
    summary_template: [],
    base_prompt: [],
    initial_answer_prompt: [],
    summary_prompt: [],
    currentSummary: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          name,
          description,
          userInitiated,
          base_prompt_intro,
          summary_template,
          base_prompt,
          initial_answer_prompt,
          summary_prompt,
          currentSummary,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(new Project(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ProjectCreateForm")}
      {...rest}
    >
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name: value,
              description,
              userInitiated,
              base_prompt_intro,
              summary_template,
              base_prompt,
              initial_answer_prompt,
              summary_prompt,
              currentSummary,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description: value,
              userInitiated,
              base_prompt_intro,
              summary_template,
              base_prompt,
              initial_answer_prompt,
              summary_prompt,
              currentSummary,
            };
            const result = onChange(modelFields);
            value = result?.description ?? value;
          }
          if (errors.description?.hasError) {
            runValidationTasks("description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("description", description)}
        errorMessage={errors.description?.errorMessage}
        hasError={errors.description?.hasError}
        {...getOverrideProps(overrides, "description")}
      ></TextAreaField>
      <SwitchField
        label="User initiated"
        defaultChecked={false}
        isDisabled={false}
        isChecked={userInitiated}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              name,
              description,
              userInitiated: value,
              base_prompt_intro,
              summary_template,
              base_prompt,
              initial_answer_prompt,
              summary_prompt,
              currentSummary,
            };
            const result = onChange(modelFields);
            value = result?.userInitiated ?? value;
          }
          if (errors.userInitiated?.hasError) {
            runValidationTasks("userInitiated", value);
          }
          setUserInitiated(value);
        }}
        onBlur={() => runValidationTasks("userInitiated", userInitiated)}
        errorMessage={errors.userInitiated?.errorMessage}
        hasError={errors.userInitiated?.hasError}
        {...getOverrideProps(overrides, "userInitiated")}
      ></SwitchField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Divider>
      <TextAreaField
        label="Base prompt intro"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              userInitiated,
              base_prompt_intro: value,
              summary_template,
              base_prompt,
              initial_answer_prompt,
              summary_prompt,
              currentSummary,
            };
            const result = onChange(modelFields);
            value = result?.base_prompt_intro ?? value;
          }
          if (errors.base_prompt_intro?.hasError) {
            runValidationTasks("base_prompt_intro", value);
          }
          setBase_prompt_intro(value);
        }}
        onBlur={() =>
          runValidationTasks("base_prompt_intro", base_prompt_intro)
        }
        errorMessage={errors.base_prompt_intro?.errorMessage}
        hasError={errors.base_prompt_intro?.hasError}
        {...getOverrideProps(overrides, "base_prompt_intro")}
      ></TextAreaField>
      <TextAreaField
        label="Summary template"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              userInitiated,
              base_prompt_intro,
              summary_template: value,
              base_prompt,
              initial_answer_prompt,
              summary_prompt,
              currentSummary,
            };
            const result = onChange(modelFields);
            value = result?.summary_template ?? value;
          }
          if (errors.summary_template?.hasError) {
            runValidationTasks("summary_template", value);
          }
          setSummary_template(value);
        }}
        onBlur={() => runValidationTasks("summary_template", summary_template)}
        errorMessage={errors.summary_template?.errorMessage}
        hasError={errors.summary_template?.hasError}
        {...getOverrideProps(overrides, "summary_template")}
      ></TextAreaField>
      <TextAreaField
        label="Base prompt"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              userInitiated,
              base_prompt_intro,
              summary_template,
              base_prompt: value,
              initial_answer_prompt,
              summary_prompt,
              currentSummary,
            };
            const result = onChange(modelFields);
            value = result?.base_prompt ?? value;
          }
          if (errors.base_prompt?.hasError) {
            runValidationTasks("base_prompt", value);
          }
          setBase_prompt(value);
        }}
        onBlur={() => runValidationTasks("base_prompt", base_prompt)}
        errorMessage={errors.base_prompt?.errorMessage}
        hasError={errors.base_prompt?.hasError}
        {...getOverrideProps(overrides, "base_prompt")}
      ></TextAreaField>
      <TextAreaField
        label="Initial answer prompt"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              userInitiated,
              base_prompt_intro,
              summary_template,
              base_prompt,
              initial_answer_prompt: value,
              summary_prompt,
              currentSummary,
            };
            const result = onChange(modelFields);
            value = result?.initial_answer_prompt ?? value;
          }
          if (errors.initial_answer_prompt?.hasError) {
            runValidationTasks("initial_answer_prompt", value);
          }
          setInitial_answer_prompt(value);
        }}
        onBlur={() =>
          runValidationTasks("initial_answer_prompt", initial_answer_prompt)
        }
        errorMessage={errors.initial_answer_prompt?.errorMessage}
        hasError={errors.initial_answer_prompt?.hasError}
        {...getOverrideProps(overrides, "initial_answer_prompt")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Divider>
      <TextAreaField
        label="Summary prompt"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              userInitiated,
              base_prompt_intro,
              summary_template,
              base_prompt,
              initial_answer_prompt,
              summary_prompt: value,
              currentSummary,
            };
            const result = onChange(modelFields);
            value = result?.summary_prompt ?? value;
          }
          if (errors.summary_prompt?.hasError) {
            runValidationTasks("summary_prompt", value);
          }
          setSummary_prompt(value);
        }}
        onBlur={() => runValidationTasks("summary_prompt", summary_prompt)}
        errorMessage={errors.summary_prompt?.errorMessage}
        hasError={errors.summary_prompt?.hasError}
        {...getOverrideProps(overrides, "summary_prompt")}
      ></TextAreaField>
      <TextField
        label="Current summary"
        isRequired={false}
        isReadOnly={false}
        value={currentSummary}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              name,
              description,
              userInitiated,
              base_prompt_intro,
              summary_template,
              base_prompt,
              initial_answer_prompt,
              summary_prompt,
              currentSummary: value,
            };
            const result = onChange(modelFields);
            value = result?.currentSummary ?? value;
          }
          if (errors.currentSummary?.hasError) {
            runValidationTasks("currentSummary", value);
          }
          setCurrentSummary(value);
        }}
        onBlur={() => runValidationTasks("currentSummary", currentSummary)}
        errorMessage={errors.currentSummary?.errorMessage}
        hasError={errors.currentSummary?.hasError}
        {...getOverrideProps(overrides, "currentSummary")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
