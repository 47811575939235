import {Flex} from "@aws-amplify/ui-react";
import {useEffect, useState} from "react";
import {getMaxContext, addSummary, getLastAssistantResponse} from "../../store/state";
import {aiMessage, generateSummaryContext, tokenCount} from "../../utils/openai";
import {SummaryForm} from "../../ui-components";
import BeatLoader from "react-spinners/BeatLoader";
import {streamer} from "../../utils/streamer";

import {useDispatch, useSelector} from "react-redux";
import {setSummary, setSummaryLoading, setCurrentResponseId} from "./summarySlice";


const Summary = ({pageWidth, handleNavigation}) => {
    const [edit, setEdit] = useState(false);
    const [tokens, setTokens] = useState(0);

    const dispatch = useDispatch();
    const {currentProjectId } = useSelector((state) => state.user);
    const {summary, updateSummary, currentResponseId, summaryLoading} = useSelector((state) => state.summary);
    const {interviewProject} = useSelector((state) => state.project);

    const [summaryText, setSummaryText] = useState(summary)

    // Insure the child chat component is re-rendered on resize
    useEffect(() => {
        console.log(`Summary - useEffect PageWidth:`, pageWidth);
        getMaxContext(currentProjectId, summary, true, true).then((history) => {
            setTokens(tokenCount(JSON.stringify(history)));
        })
        if (!currentResponseId) {
           getLastAssistantResponse(currentProjectId).then((response) => response && dispatch(setCurrentResponseId(response.id)));
        }
    },[pageWidth, summaryLoading, summary])

    useEffect(() => {
        if (summaryLoading) return;
        if (updateSummary) {
            console.log("summary - Starting recalculation of summary.");
        } else {
            console.log("summary - Completed recalculation of summary.");
            setSummaryLoading(false);
        }
    },[updateSummary, summaryLoading])

    const sendMessage = async (context) => {
        setTokens(tokenCount(JSON.stringify(context)));
        try {
            const data = JSON.stringify(aiMessage(context, true));
            console.log(`SendMessage - request data: ${data}`);
            await streamer(data, (value) => dispatch(setSummary(value)), handleSendComplete)
        } catch (error) {
            console.error('Error summary generation streaming:', error);
            alert(`Submit error: ${error}. Data not saved, try again.`);
            dispatch(setSummaryLoading(false));
        }
    };

    // Handle saving of Q&A store and manage interview/interaction state
    const handleSubmit = async () => {
        if (summaryLoading) return;
        dispatch(setSummaryLoading(true));
        console.log("EntityList - Handle Submit");
        const context = await generateSummaryContext(currentProjectId, summary, interviewProject);
        await sendMessage(context);
    }

    const handleSendComplete = (text) => {
        dispatch(setSummary(text));
        dispatch(setSummaryLoading(false));
        if (text) addSummary(currentProjectId, currentResponseId, text, (value) => dispatch(setSummary(value)))
            .then(result => console.log(`Summary - handle submit - New summary: ${result.id}`));
    }

    const handleEdit = async () => {
        dispatch(setSummary(summaryText));
        addSummary(currentProjectId, currentResponseId, summaryText, (value) => dispatch(setSummary(value)))
            .then(result => console.log(`Summary - handle edit - New summary: ${result.id}`));
        setEdit(false);
    }

    return (
        <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginTop={40}
        >
            <BeatLoader
                onClick={() => dispatch(setSummaryLoading(false))}
                color="grey"
                loading={summaryLoading}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            <SummaryForm
                refresh={edit ? handleEdit : handleSubmit}
                interview={handleNavigation}
                edit={() => setEdit(!edit)}
                summary={summary}
                summaryText={summaryText}
                tokens={tokens}
                width={`${pageWidth}px`}
                overrides={{
                    'Summary Text': {
                        display: edit ? "none" : "block"
                    },
                    Button46571368: {
                        children: edit ? "Cancel" : "Edit",
                        isDisabled: summaryLoading,
                    },
                    Button46341364: {
                        children: edit ? "Save" : "Refresh",
                        isDisabled: summaryLoading,
                    },
                    TextAreaField: {
                        height: "unset",
                        rows: 20,
                        onChange: (e) => setSummaryText(e.target.value),
                        resize: "vertical",
                        display: edit ? "block" : "none",
                        isDisabled: summaryLoading,
                    },
                }}
            />
        </Flex>
    )
}

export default Summary;