import {Flex} from "@aws-amplify/ui-react";
import BeatLoader from "react-spinners/BeatLoader";
import {QAForm1} from "../../ui-components";
import {useEffect} from "react";
import {aiMessage, createUserMessage, tokenCount} from "../../utils/openai";
import {streamer} from "../../utils/streamer";
import Markdown from "../../components/Markdown";

const Chat = ({pageWidth, handleSubmit, handleResponse, loading, setLoading,
                  questionText, setQuestionText, answerText, setAnswerText, tokens, setTokens, userInitiated,
                  setUserInitiated, interviewProject}) => {

    useEffect(() => {
        console.log(`Chat - userEffect PageWidth:`, pageWidth);
        console.log(`Chat: interviewProject: ${interviewProject}`);
    },[pageWidth, loading, questionText])

    const handleSendComplete = (text) => {
        setLoading(false)
        setQuestionText(text);
        // TODO This  is confusing. The setAnswerText logic, essentially clearing it out when it's been handled seems
        // unclear and distributed. Here it's handed by the options handleResponse but that's not an evident side effect.
        // Handle response should be required and take care of this.
        // There various states for use and ai text seem to be to complex and distributed. Need more encapsulation.
        if (handleResponse) handleResponse(text);
        else setAnswerText("");
    }

    const sendMessage = async (history) => {
        setTokens(tokenCount(JSON.stringify(history)));
        try {
            const data = JSON.stringify(aiMessage(history, userInitiated));
            console.log(`startStream data: ${data}`);
            await streamer(data, setQuestionText, handleSendComplete)
        } catch (error) {
            console.error('Error starting streaming:', error);
            alert(error);
            setLoading(false);
        }
    };

    const onClickHandler  = () => {
        // Chat supports a resume feature where you can switch between user and assistant driven chat and resume
        // assistant driven.
        setLoading(true);
        if (answerText !== "") {
            if (handleSubmit)
                 handleSubmit().then(({history, send}) => {
                     if (send) sendMessage(history);
                     else setLoading(false);
                 });
            else sendMessage([createUserMessage(answerText)]).then(() => console.log("Chat message send completed."));
        }
    }

    const helpClickHandler = () => {
        if (userInitiated) setQuestionText("");
        setUserInitiated(!userInitiated);
    }

    return (
        <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                marginTop={40}
                marginBottom={40}
            >
                <BeatLoader
                    onClick={() => setLoading(false)}
                    color="grey"
                    loading={loading}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                <QAForm1
                    assistant={questionText}
                    user={answerText}
                    tokens={tokens}
                    width={`${pageWidth}px`}
                    overrides={{
                        TextContent45121570: {
                            display: userInitiated ? 'none' : 'block',
                            minHeight: "80px",
                            padding: "15px",
                            borderRadius: "5px"
                        },
                        AssistantQuestion: {
                            display: userInitiated ? 'none' : 'block',
                        },
                        TextContent45121574: {
                            backgroundColor: "rgb(255,255,255)",
                            display: userInitiated ? 'block' : 'none',
                            minHeight: "5px",
                            borderRadius: "5px"
                        },
                        AssistantAnswer: {
                            display: userInitiated ? 'block' : 'none',
                            children: <Markdown>
                                        {questionText}
                                      </Markdown>
                        },
                        TextAreaField: {
                            height: "unset",
                            rows: userInitiated ? 3 : 10,
                            onChange: (e) => setAnswerText(e.target.value),
                            placeholder: userInitiated ? "Ask any question." : "Enter your answer.",
                            resize: "vertical",
                        },
                        Placeholder: {
                            display: interviewProject ? "block" : "none"
                        },
                        Button45121579: {
                            onClick: onClickHandler,
                            isDisabled: loading,
                            children: "Submit"
                        },
                        "Question Button": {
                            onClick: helpClickHandler,
                            display: interviewProject ? "block" : "none",
                        },
                        Button45121581: {
                            width: "200px",
                            color: "rgb(62,125,238)",
                            backgroundColor: "white",
                            children: userInitiated ? "Resume Interview" : "Need Help?",
                        },
                    }}
                />
            </Flex>
        </Flex>
    )
}

export default Chat;

