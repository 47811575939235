import {DBStart, DBGet, DBSave, DBGetByValue, testUserName, createTestOrg, DBUpdate} from "../utils/db";
import {Organization, User, Project, Question, Response, Summary} from "../models";
import {generateContext, truncateContext} from "../utils/openai";

// replace by db sorting
export const sortBySeqAndUpdatedAt = (a, b) => {
    // Sort by sequence descending
    if (a.sequence > b.sequence) {
        return -1;
    } else if (a.sequence < b.sequence) {
        return 1;
    } else {
        const dateA = new Date(a.updatedAt), dateB = new Date(b.updatedAt);
        return dateB - dateA;
    }
}

const getDefaultUser = async () => {
    const results = await DBGetByValue(User, "name", testUserName);
    if (!results) return;
    return results.length > 0 ? results[0] : results;
}

export const getUserOrganization = async (user) => {
    return DBGet(Organization, user.organizationID);
}

export const getUserProject = async (user) => {
    if (!user.defaultProjectID) {
        const org = await getUserOrganization(user);
        const projects = await org.Projects.toArray();
        return (projects.length > 0) ? projects[0] : null;
    }
    return await DBGet(Project, user.defaultProjectID);
}

export const initProjectChat = async (projectId,
                                      setInterviewProject,
                                      setInitialAnswer,
                                      setAssistantText,
                                      setUserText,
                                      setSummary,
                                      setQuestionText,
                                      setAnswerText,
                                      setCurrentResponseId,
                                      setRefreshHeader,
                                      setUserInitiated) => {
    const project = await fetchProject(projectId);
    const interview = !project.userInitiated;
    setInterviewProject(interview);
    setUserInitiated(!interview);
    setInitialAnswer(project.initial_answer_prompt);
    setAssistantText("");
    setUserText("");
    // TODO This section of code is WET and similar to the login initialization of a project
    if (project.currentSummary) {
        const currentSummary = await DBGet(Summary, project.currentSummary);
        if (currentSummary) setSummary(currentSummary.text);
        else setSummary(project.summary_template);
    } else {
        setSummary(project.summary_template);
    }
    // Re-initialize the question and answer if an interview already underway
    if (interview) {
        const lastResponse = await getLastAssistantResponse(projectId);
        if (lastResponse) {
            setCurrentResponseId(lastResponse.id);
            setQuestionText(`LAST INTERVIEW ANSWER:\n\n${lastResponse.questionText} \n\nAnswer: ${lastResponse.text}`);
            setAnswerText(project.initial_answer_prompt);
        } else {
            setCurrentResponseId(null);
            setQuestionText("");
            setAnswerText(project.initial_answer_prompt);
        }

    } else {
        setQuestionText("");
        setAnswerText("");
    }
    setRefreshHeader(true);
    console.log(`Init Project Chat: projectId: ${projectId} interViewProject: ${interview}`);
}

const initUser = async (authUser) => {
    const user = authUser.user || authUser;
    const email = user.attributes.email;
    let currentUser = await DBGetByValue(User, "email", email);
    if (!currentUser) {
        console.error(`State - initUser - authUser not found: ${email}`)
        return;
    }
    return currentUser;

}

export const loginCurrentUser = async (authUser,
                                       setCurrentUserId,
                                       setUserOrgId,
                                       setAdminUser,
                                       setCurrentOrganizationId,
                                       setCurrentProjectId,
                                       setInitialAnswer,
                                       setInterviewProject,
                                       setSummary,
                                       setAnswerText,
                                       setQuestionText,
                                       setCurrentResponseId,
                                       setUserInitiated)=> {
    await DBStart();
    //TODO This code is Wet and used in ProjectList a other places where key entities like org, user and project chagen.
    let defaultUser = await getDefaultUser();
    let newEnv = false;
    let user;
    // For a completely new database, no user will exist so this will bootstrap a default user that can admin
    // from within the app to create users and orgs.
    // TODO The authenticated user to start must fit the pattern below. Need to setup rolls etc..
    if (!defaultUser) {
        const defOrg = await createTestOrg();
        if (!defOrg) return;
        newEnv = true;
        defaultUser = await getDefaultUser();
    }
    console.log("State - loginCurrentUser");
    if (newEnv)
        user = defaultUser;
    else
        user = await initUser(authUser);
    if (!user) return;
    const adminUser = user.adminUser || user.email.includes("dbrandt") || user.email.includes("dbbrandt");
    console.log("State - loginCurrentUser", JSON.stringify(user));
    const org = await getUserOrganization(user);
    const project = await getUserProject(user)
    let summary = project?.summary_template;
    const interviewProject = !project?.userInitiated;
    const initialAnswer = interviewProject ? project?.initial_answer_prompt : "";

    if (project?.currentSummary) {
       const currentSummary = await DBGet(Summary, project.currentSummary);
       if (currentSummary) summary = currentSummary.text
       else summary = project.summary_template;
    }

    setCurrentOrganizationId(org.id);
    setCurrentProjectId(project?.id);
    setInterviewProject(interviewProject);
    setUserInitiated(!interviewProject);
    setSummary(summary);
    setInitialAnswer(initialAnswer);

    setQuestionText("");
    if (project && interviewProject) {
        const lastResponse = await getLastAssistantResponse(project?.id);
        if (lastResponse) {
            setQuestionText(`LAST INTERVIEW ANSWER:\n\n${lastResponse.questionText} \n\nAnswer: ${lastResponse.text}`);
            setAnswerText(initialAnswer);
            setCurrentResponseId(lastResponse.id);
        }
    } else {
        setAnswerText(initialAnswer);
    }
    setAdminUser(adminUser);
    setCurrentUserId(user.id);
    setUserOrgId(user.organizationID);
    return user;
}

export const fetchOrg = async (organizationId) => {
    return await DBGet(Organization, organizationId);
}

export const fetchUser = async (userId) => {
    return await DBGet(User,  userId);
}

export const fetchProject = async (projectId) => {
    return await DBGet(Project,  projectId);
}

export const addQuestion = async (currentProjectId, questionText, setCurrentQuestionId) => {
    // console.log(`State - addQuestion: ${question}`);
    if (!currentProjectId) return;
    const question = await DBSave(Question, {
        text: questionText,
        projectID: currentProjectId,
    });
    setCurrentQuestionId(question.id);
    return question;
}

export const addResponse = async (currentProjectId, currentUserId, userInitiated, questionId, answer, question, setCurrentResponseId) => {
    console.log(`State - addResponse questionId: ${questionId} question: ${question} answer: ${answer}`);
    const project = await DBGet(Project, currentProjectId);
    const responses = await sortedResponses(project);
    const sequence = responses.length + 1;
    console.log(`State - projectId: ${project.id} userId: ${currentUserId} sequence: ${sequence}`);
    if (!(project && currentUserId && sequence)) return;
    const response = await DBSave(Response, {
        text: answer,
        userInitiated: userInitiated,
        questionText: question,
        sequence: sequence,
        projectID: currentProjectId,
        userID: currentUserId,
        questionID: questionId
    });
    if (setCurrentResponseId) setCurrentResponseId(response.id);
    return response;
}

export const addSummary = async (currentProjectId, currentResponseId, summaryText, setSummary) => {
    console.log(`State - addSummary project: ${currentProjectId} newSummary: ${summaryText}`);
    const summary = await DBSave(Summary, {
        text: summaryText,
        projectID: currentProjectId,
        responseID: currentResponseId
    });
    // TODO Update the project.currentSummary with summary.id
    const project = await fetchProject(currentProjectId);
    const updated = (s) => { s.currentSummary = summary.id };
    await DBUpdate(Project, project, updated);
    if (setSummary) setSummary(summaryText);
    return summary;
}

export const sortedResponses= async (projectId) => {
    const project = await DBGet(Project,  projectId);
    const responses = await project?.Responses.toArray();
    responses.sort(sortBySeqAndUpdatedAt);
    return  responses ;
}

export const getLastAssistantResponse = async (projectId) => {
    const responses = await sortedResponses(projectId);
    if (responses.length > 0) {
        const lastInterviewResponse = responses.filter((a) => !a.userInitiated);
        if (lastInterviewResponse.length > 0) {
            return lastInterviewResponse[0];
        }
    }
}

export const sortedQuestions = async (project) => {
    const questions = await project.Questions.toArray();
    questions.sort((a, b) => {
        const dateA = new Date(a.updatedAt), dateB = new Date(b.updatedAt);
        return dateB - dateA;
    });
    return questions;
}

export const sortedSummaries = async (project) => {
    const summaries = await project.Summaries.toArray();
    summaries.sort((a, b) => {
        const dateA = new Date(a.updatedAt), dateB = new Date(b.updatedAt);
        return dateB - dateA;
    });
    return summaries;
}

// The most amount of response history that can be sent to the AI based on context size
export const getMaxContext = async (currentProjectId, summary, interview) => {
    if (!currentProjectId) return [];
    const project = await fetchProject(currentProjectId);
    const responses = await sortedResponses(currentProjectId);
    const context = await generateContext(project, responses, summary, interview);
    console.log("State - getMaxContext - current context length:", context.length);
    if (context.length === 0) return context;
    const maxContext = truncateContext(context);
    console.log(`GenerateRequestData- context length - original: ${context.length} truncated:${maxContext.length}`);
    return maxContext;
}

