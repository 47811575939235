import {Button, Flex} from "@aws-amplify/ui-react";
import {QACardCollection} from "../../ui-components";
import Markdown from "../../components/Markdown";
import {useEffect, useState} from "react";
import {sortedResponses} from "../../store/state";
import {useSelector} from "react-redux";

const Transcript = ({pageWidth}) => {
    const {currentUserId, currentProjectId } = useSelector((state) => state.user);
    const [answers, setAnswers] = useState([]);
    const [onePage, setOnePage] = useState(false);

    useEffect(() => {
        if (currentUserId && currentProjectId) sortedResponses(currentProjectId).then((responses) => setAnswers(responses));
    },[currentUserId,  currentProjectId])

    return (
        <Flex
            direction="column"
            width={pageWidth}
            justifyContent="center"
            alignItems="center"
            marginTop={40}
            marginBottom={40}
        >
            <QACardCollection
                itemsPerPage={onePage ? 1000 : 6}
                items={answers}
                width={pageWidth}
                overrideItems={({item}) => ({
                    width: "unset",
                    backgroundColor: (item.userInitiated ? "rgba(100,149,237,0.1)" : "rgba(119,202,133,0.1)"),
                    overrides: {
                        sequence: {
                            color: (item.userInitiated ? "rgb(83,164,97,1)" : "rgb(85,138,232,1)"),
                        },
                        question: {
                            children: item.userInitiated ? item.text : <Markdown>{item.questionText}</Markdown>,
                            color: item.userInitiated ? "rgb(83,164,97,1)" : "rgb(85,138,232,1)",
                        },
                        answer: {
                            children: item.userInitiated ? <Markdown>{item.questionText}</Markdown> : item.text,
                            color: item.userInitiated ? "rgb(20,94,225)" : "rgba(63,125,74,1)",
                        }
                    }
                })}
            />
            <Button
                onClick={() => setOnePage(!onePage)}
                marginTop={10}
                fontSize="small"
            >
                {onePage ? 'Paginate' : `Single Page`}
            </Button>
        </Flex>
    )
}

export default Transcript;