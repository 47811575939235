/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Text, TextAreaField, View } from "@aws-amplify/ui-react";
export default function QAForm1(props) {
  const { assistant, user, tokens, submit, navigate, overrides, ...rest } =
    props;
  return (
    <Flex
      gap="5px"
      direction="column"
      width="800px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 5px 0px 5px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "QAForm1")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "Frame 1")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="5px 10px 5px 10px"
          {...getOverrideProps(overrides, "Form")}
        >
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            border="1px SOLID rgba(174,179,183,1)"
            padding="0px 9px 0px 9px"
            {...getOverrideProps(overrides, "TextContent45121570")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              color="rgba(100,149,237,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={assistant}
              {...getOverrideProps(overrides, "AssistantQuestion")}
            ></Text>
          </Flex>
          <TextAreaField
            width="unset"
            height="unset"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            rows={15}
            value={user}
            {...getOverrideProps(overrides, "TextAreaField")}
          ></TextAreaField>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-end"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 0px 10px 0px"
            {...getOverrideProps(overrides, "Frame 5")}
          >
            <View
              width="unset"
              height="unset"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Placeholder")}
            >
              <View
                width="399px"
                height="37px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                position="absolute"
                top="43px"
                left="186px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 6")}
              ></View>
            </View>
            <Flex
              gap="20px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 20px 0px 20px"
              {...getOverrideProps(overrides, "Submit Button")}
            >
              <Button
                width="128px"
                height="48px"
                shrink="0"
                size="default"
                isDisabled={false}
                variation="primary"
                children="submit"
                onClick={submit}
                {...getOverrideProps(overrides, "Button45121579")}
              ></Button>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="51px"
                height="26px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children=""
                {...getOverrideProps(overrides, "Text")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-end"
              alignItems="center"
              overflow="hidden"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Question Button")}
            >
              <Button
                width="93px"
                height="32px"
                shrink="0"
                backgroundColor="rgba(100,149,237,1)"
                size="default"
                isDisabled={false}
                variation="primary"
                children="question?"
                onClick={navigate}
                {...getOverrideProps(overrides, "Button45121581")}
              ></Button>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            border="1px SOLID rgba(174,179,183,1)"
            padding="0px 9px 0px 9px"
            {...getOverrideProps(overrides, "TextContent45121574")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              color="rgba(104,112,120,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={assistant}
              {...getOverrideProps(overrides, "AssistantAnswer")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 7")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(174,179,183,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={tokens}
            {...getOverrideProps(overrides, "tokens")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
