/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Text, TextAreaField } from "@aws-amplify/ui-react";
export default function SummaryForm(props) {
  const {
    refresh,
    interview,
    tokens,
    summary,
    edit,
    summaryText,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="800px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="12px 14px 12px 14px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "SummaryForm")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="10px 20px 10px 20px"
        {...getOverrideProps(overrides, "InputFrame")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          padding="9px 19px 9px 19px"
          {...getOverrideProps(overrides, "Summary Text")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(0,0,0,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={summary}
            {...getOverrideProps(overrides, "summary")}
          ></Text>
        </Flex>
        <TextAreaField
          width="unset"
          height="unset"
          shrink="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          value={summaryText}
          {...getOverrideProps(overrides, "TextAreaField")}
        ></TextAreaField>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="10px 20px 10px 20px"
        {...getOverrideProps(overrides, "ButtonFrame")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 5px 0px 5px"
          {...getOverrideProps(overrides, "Edit Button")}
        >
          <Button
            width="96px"
            height="34px"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Edit"
            onClick={edit}
            {...getOverrideProps(overrides, "Button46571368")}
          ></Button>
        </Flex>
        <Flex
          gap="20px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 20px 0px 20px"
          {...getOverrideProps(overrides, "Submit Button")}
        >
          <Button
            width="128px"
            height="48px"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Refresh"
            onClick={refresh}
            {...getOverrideProps(overrides, "Button46341364")}
          ></Button>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Question Button")}
        >
          <Button
            width="93px"
            height="32px"
            shrink="0"
            backgroundColor="rgba(100,149,237,1)"
            size="default"
            isDisabled={false}
            variation="primary"
            children="interview"
            onClick={interview}
            {...getOverrideProps(overrides, "Button46341367")}
          ></Button>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 7")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(174,179,183,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={tokens}
          {...getOverrideProps(overrides, "tokens")}
        ></Text>
      </Flex>
    </Flex>
  );
}
