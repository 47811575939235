import {
    OrganizationItemCollection,
    OrganizationCreateForm,
    OrganizationUpdateForm
} from "../../ui-components";
import {Organization} from "../../models";
import EntityList from "../../components/EntityList";
import {useEffect, useState} from "react";
import {fetchOrg, fetchUser} from "../../store/state";

import {useSelector, useDispatch} from "react-redux";
import {setCurrentUserId, setCurrentProjectId, setCurrentOrgId} from "../user/userSlice";
import {setQuestionText, setAnswerText, setAssistantText, setUserText} from "../chat/chatSlice";
import {setCurrentResponseId} from "../summary/summarySlice";

const OrganizationList = () => {
    const [items, setItems] = useState([]);

    const dispatch = useDispatch();
    const {currentUserId, currentOrganizationId} = useSelector((state) => state.user);

    const fetchData = async () => {
        const items = await fetchOrg(currentOrganizationId);
        setItems([items]);
    }

    useEffect(() => {
        fetchData().then(() =>console.log("OrganizationList - useEffect - items fetched."));
    },[currentOrganizationId])

    const handleOnChange = async (orgId) => {
        dispatch(setCurrentOrgId(orgId));
        const user = await fetchUser(currentUserId);
        if (user.organizationID !== orgId) dispatch(setCurrentUserId(null));
        dispatch(setCurrentProjectId(null));
        dispatch(setQuestionText(""));
        dispatch(setAnswerText(""));
        dispatch(setAssistantText(""));
        dispatch(setUserText(""));
        dispatch(setCurrentResponseId());
    }

    return (
        <EntityList
            entity = {Organization}
            items = {items}
            listComponent = {OrganizationItemCollection}
            addComponent = {OrganizationCreateForm}
            updateComponent = {OrganizationUpdateForm}
            onChangeHandler = {(orgId) => handleOnChange(orgId)}
            onRefreshHandler = {fetchData}
        />
    )
}

export default OrganizationList;
