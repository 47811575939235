import GPT3Tokenizer from 'gpt3-tokenizer';
import {fetchProject, sortedResponses} from "../store/state";

export const gptChatUrl = 'https://api.openai.com/v1/chat/completions';

export const gpt4_model = "gpt-4-0314";
export const gpt3_model = "gpt-3.5-turbo";
export const apiKey = "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";

const tokenizer = new GPT3Tokenizer({ type: 'gpt3' }); // or 'codex'

export const tokenCount = (str) => {
    const tokens = tokenizer.encode(str);
    return tokens.text.length;
}
const maxTokens = 6000;

// const apiKey = "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";
// const chatEndpoint = 'https://api.openai.com/v1/chat/completions';

export const aiMessage = (message, userInitiated) => ({
    model: gpt4_model,
    messages: message,
    temperature: 0.9,
    max_tokens: userInitiated ? 2000 : 200,
    stream: true
});

export const addContextInteraction = (context, assistantText, userText, userFirst ) => {
    if (userFirst) {
        if (!!userText) context.push({role: "user", content: userText});
        if (!!assistantText) context.push({role: "assistant", content: assistantText});
    } else {
        if (!!assistantText) context.push({role: "assistant", content: assistantText});
        if (!!userText) context.push({role: "user", content: userText});
    }
}

export const generateSummaryContext = async (currentProjectId, summary, interviewProject) => {
    console.log("Generating Request Data for summary");
    const project = await fetchProject(currentProjectId);
    const systemContent = (`${project?.summary_prompt} \n(Prior Summary)\n ${summary || ""}\n\n(Q&A Session)\n`).trim();
    console.log(`System Content: ${systemContent}`);
    const context =  [{role: "system", content: systemContent}];
    const responses = await sortedResponses(currentProjectId);
    const messages = (interviewProject ? [...responses].filter((m) => !m.userInitiated) : responses).reverse();
    messages.forEach((r) => {
        addContextInteraction(context, r.questionText, r.text, r.userInitiated);
    })
    const truncatedContext = truncateContext(context);
    truncatedContext.push({role: "user", content: "\n(End of Q&A Session)\n Please generate the summary now."});
    console.log(`GenerateRequestData- context length - original: ${context.length} truncated:${truncatedContext.length}`);
    return truncatedContext;
}

// TODO much of this should be refacted out to some business utility. It got too much business logic
// unrelated to openAI.

export const generateContext = (project, responses, summary, isInterview) => {
    console.log("OpenAI: generateCotext");
    let systemContent;
    // Construct system message
    if (isInterview) {
        systemContent = (`${project?.base_prompt_intro} ${summary || ""} ${project.base_prompt || ""}`).trim();
    } else {
        const questionPrompt =
            `Answer the last question posed by the user  in this conversation as best you can considering any
            summary provided and all interactions with the user prior to their questions. Pay special attention to the general
            subject mater of the conversation to make your answers as relevant to the question in context as possible.`;
        systemContent = `${questionPrompt} ${summary || ""}`.trim();
    }
    console.log(systemContent);
    let history =  !!systemContent ? [{role: "system", content: systemContent}] : [];
    history = generateResponseContext(history,  responses, isInterview);
    console.log("OpenAI - generate history results:");
    console.log(history);
    return history;
}

export const generateResponseContext = (history, responses, isInterview) => {
    const messages = [...responses].reverse();
    if (isInterview) {
        const interviewResponses = messages.filter((m) => !m.userInitiated);
        interviewResponses.forEach((r) => {
            addContextInteraction(history, r.questionText, r.text, r.userInitiated);
        })
    } else {
        messages.forEach((r) => {
            addContextInteraction(history, r.questionText, r.text, r.userInitiated);
        })
    }
    return history;
}

export const truncateContext = (history, size = maxTokens) => {
    console.log("OpenAI - Truncated History");
    if (tokenCount(JSON.stringify(history)) < size) return history;
    const truncated = [];
    let remaining = size;
    console.log(`OpenAI - TruncatedHistory maxTokens: ${size} remaining: ${remaining}`);
    const systemMessage = history.reverse().pop();
    console.log(`OpenAI - system message: ${JSON.stringify(systemMessage)}`);
    history.forEach((h) => {
        const tokens = tokenCount(JSON.stringify(h));
        if (remaining - tokens > 0) {
            remaining -= tokens;
            truncated.push(h)
        }
    })
    truncated.push(systemMessage);
    truncated.reverse();
    console.log(truncated);
    return  truncated;
}

export const createUserMessage = (text) => {
    return {role: "user", content: text}
}

export const createAssistantMessage = (text) => {
    return {role: "assistant", content: text}
}

export const createSystemMessage = (text) => {
    return {role: "system", content: text}
}


export const sendMessage = async (model, history, userInitiated) => {
    try {
        const data = JSON.stringify({
            model: model,
            messages: history,
            temperature: 0.9,
            max_tokens: userInitiated ? 2000 : 200
        });
        console.log(`sendMessage data: ${data}`);
        return await fetch(gptChatUrl, {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`
            },

            body: data
        }).then(response => response.json())
            .then(data => {
                return data.choices[0].message.content;
            });
    } catch (error) {
        console.error('Error starting streaming:', error);
        alert(error);
    }
};



