import {
    QuestionItemCollection,
    QuestionCreateForm,
    QuestionUpdateForm
} from "../../ui-components";
import {Question} from "../../models";
import EntityList from "../../components/EntityList";
import {useEffect, useState} from "react";
import {fetchProject, sortedQuestions} from "../../store/state";

import {useDispatch, useSelector} from "react-redux";
import {setCurrentQuestionId} from "../chat/chatSlice";

const QuestionList = () => {
    const [items, setItems] = useState([])

    const dispatch = useDispatch();
    const {currentProjectId } = useSelector((state) => state.user);
    const {currentQuestionId} = useSelector((state) => state.chat);

    const fetchData = async () => {
        const project = await fetchProject(currentProjectId);
        if (!project) setItems([])
        else {
            const questions = await sortedQuestions(project);
            console.log("QuestionList - question");
            console.log(questions);
            setItems(questions);
        }
    }

    useEffect(() => {
        fetchData().then(() => console.log("QuestionList - fetching question"));
    },[currentProjectId, currentQuestionId])


    const handleOnChange = async (questionId) => {
        dispatch(setCurrentQuestionId(questionId));
    }

    return (
        <EntityList
            parent = {{name: 'project', id: currentProjectId}}
            entity = {Question}
            items = {items}
            listComponent = {QuestionItemCollection}
            addComponent = {QuestionCreateForm}
            updateComponent = {QuestionUpdateForm}
            onChangeHandler = {handleOnChange}
            onRefreshHandler={ fetchData }
            resize="both"
        />
    )
}

export default QuestionList;
