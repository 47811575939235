import {apiKey, gptChatUrl} from "./openai";

export const streamer = async (data, setAnswer, onComplete) => {
    const convertToText = (input) => {
        // Split the input by newline to get individual "data:" entries
        const entries = input.split('\n');
        // console.log(`convertToText: input: ${entries}`);
        // Map each entry to a valid JSON object, stripping the leading "data: "
        const textEntries = entries.filter(entry => entry.trim() !== '').map(entry => {
            const jsonString = entry.replace('data: ', '');
            // console.log(`convertToText: jsonString: ${jsonString}`);
            if (jsonString === '[DONE]') return "";
            try {
                const data = JSON.parse(jsonString);
                return data.choices[0].delta.content;
            } catch (error) {
                // console.log(`convertToText JsonParse Error: ${error}`);
                return "";
            }
        });
        return textEntries.join("");
    }

    const response = await fetch(gptChatUrl, {
        method: 'POST',
        path: gptChatUrl,
        responseType: 'stream',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
        },
        body: data
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    let text = '';
    let finalText = '';
    while (true) {
        const { done, value } = await reader.read();

        if (done) {
            onComplete(finalText);
            break;
        }

        const result =  new TextDecoder('utf-8').decode(value);
        const parsed = convertToText(result);
        text += parsed;

        // Update state with new data
        finalText += text;
        setAnswer(finalText);
        text = '';
    }
    return finalText;
}
