import { useSelector } from 'react-redux';
import {useState} from "react";
import {projectImport} from "../../utils/projectExport";
import {Button, Flex, Heading, TextField, TextAreaField} from "@aws-amplify/ui-react";
import JSONViewer from 'react-json-view';

const Import = () => {
    const {currentOrganizationId, currentProjectId, currentUserId} = useSelector((state) => state.user);
    const [data, setData] = useState();
    const [name, setName] = useState();
    const [imported, setImported] = useState();
    const [pageWidth, setPageWidth] = useState("800px");

    const handleResize = () => {
        console.log(`EntityList - HandleResize: ${window.innerWidth}`)
        const width = window.innerWidth;
        const newWidth = Math.floor(width * 90 / 100);
        console.log(`EntityList - New Width: ${newWidth}px`);
        setPageWidth(`${newWidth}px`);
    };
    const importProject = () => {
        alert("Importing project");
        if (!data) return;
        projectImport(data, name, currentProjectId, currentOrganizationId, currentUserId)
            .then((result) => setImported(result));
        handleResize();
    };

    return (
        <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            width={pageWidth}
            marginTop={20}
        >
            <Heading level={4} marginTop={20}>Project Import</Heading>
            <TextField
                label="Name"
                width={pageWidth}
                value={name}
                onChange= {(e) => setName(e.target.value)}
            />

            <TextAreaField
                value={data}
                label="JSON"
                rows={20}
                width={pageWidth}
                onChange= {(e) => setData(e.target.value)}
            />
            <Button
                backgroundColor="rgba(100,149,237,1)"
                color="white"
                onClick={importProject}
            >
                Import
            </Button>
            <JSONViewer
                style={{fontFamily: "Inter",
                    fontSize:"14px",
                    fontWeight:"300"}}
                name="Project"
                enableClipboard
                collapsed={1}
                src={imported}
            />
        </Flex>
    )
}

export default Import;