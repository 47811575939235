import { combineReducers } from 'redux';
import userReducer from '../features/user/userSlice';
import chatReducer from '../features/chat/chatSlice';
import projectReducer from '../features/project/projectSlice';
import summaryReducer from '../features/summary/summarySlice';

// Import other slice reducers as needed

const rootReducer = combineReducers({
    chat: chatReducer,
    user: userReducer,
    project: projectReducer,
    summary: summaryReducer,
    // Add other slice reducers here
});

export default rootReducer;