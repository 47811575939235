import {DataStore} from "aws-amplify";
import { Hub } from 'aws-amplify';
import {Organization, Project, User} from "../models";

export const DBEvent = async (eventType) => {
    return new Promise((resolve) => {
        Hub.listen('datastore', (data) => {
            const {event} = data.payload;
            console.log(`"DBEvebt waiting for ${eventType}: saw: ${event}`);
            if (event === eventType) {
                resolve();
            }
        });
    });
}

export const DBStart = async () => {
    await DataStore.start();
    // Wait for DataStore to be ready before proceeding
    await DBEvent('ready');
}

export const DBClear = () => {
    DataStore.stop();
    DataStore.clear();
}

export const DBGet = async (entity, id) => {
   return await DataStore.query(entity, id);
}

export const DBGetByValue = async (entity, field, value) => {
    const record = await DataStore.query(entity, (c) => c[field].eq(value));
    console.log(`DBGetByValue ${entity.name} ${field} eq ${value}`);
    console.log("DB - record[0]: ",record[0]);
    return record[0];
}

export const DBGetList = async (Entity, parentEntity, parentId) => {
    // console.log(`DBGetList ${Entity.name}s for ${parentEntity.name}ID: ${parentId}`);
    // const parentName = `${parentEntity.name.toLowerCase()}ID`;
    const childName = `${Entity.name}s`;
    const parent = await DBGet(parentEntity, parentId);
    // console.log("DB - results: ",results);
    return await parent[childName].toArray();

}

export const DBSave = async (Entity, values) => {
    // console.log("DB - DbSave");
    // console.log("DB - results: ",results);
    return await DataStore.save(new Entity(values));
}

export const DBUpdate = async (Entity, original, updates) => {
    const updated = Entity.copyOf(original, updates);
    return await DataStore.save(updated);
}

const testOrg = (name) => ({ "name": name });

const testProject = (orgId) => ({
    "organizationID": orgId,
    "base_prompt_intro": "As a Chat GPT-4, my role is to act as a software product manager by engaging in a chat interaction with you to discover additional application requirements and expand on the existing ones provided in the following summary. ",
    "base_prompt": "(End Prior summary)\n I will ask one primary question at a time, followed by three follow-up question to ensure a comprehensive understanding of the application needs. My goal is to look for summary topics where additional requirements are needed to improve the quality and completeness of that topic based on the existing summary of requirements. The existing summary includes 17 topics, and we will explore additional requrements for each of them through a series of primary and follow-up question. \n\n Primary Question 1: (discovery question)\nFollow-up Question 1.1: (related question)\nFollow-up Question 1.2: (related question)\nFollow-up Question 1.3: (related question)\n\n(You respond to the primary question, and  I will ask the three follow-up question, one at a time, waiting for your response after each question.)  \n\nAfter completing the series of follow-up question for a primary question, I will proceed to the next primary question without any special prompt.\n (Start of Q&A Session)",
    "description": "Interview a Product Manager for software system requirements",
    "initial_answer_prompt": "Press submit to start or continue the Q&A.",
    "name": "Product Manager Interview",
    "summary_prompt": ```Summarize the key business requirements gathered from a GPT-based chat Q&A session, 
    incorporating information from a previous summary and the new transcript. 
    Update the requirements summary for the proposed system while maintaining consistency with the prior summary 
    unless revisions or corrections arise from the Q&A results. Maintain the same summary categories when revising 
    the prior summary.

    (Prior Summary)```,
    "summary_template": ```
    Business Requirements Summary

    1. Problem Statement/Business Goals

    2. Target Users and Roles

    3. Key Features

    4. User Workflow and Milestones

    5. Testing and Quality Assurance

    6. Version Control and Change Management

    7. Customization and Extensibility

    8. User Onboarding and Account Management

    9. Security (No updates from the Q&A session)

    10. Scalability and Performance

    11. Integration with Third-Party Systems and APIs

    12. Payment Processing/Subscription Management

    13. Analytics and Reporting

    14. Collaboration Features

    15. Updates and Maintenance Procedures

    16. Disaster Recovery

    17. Success Metrics ```,
    "userInitiated": false
});

export const testUserName = "Test User";
const testUser = (orgId,projId ) => ({
    "seq": 1,
    "user_id": "tu1",
    "organizationID": orgId,
    "defaultProjectID": projId,
    "email": "dbbrandt@gmail.com",
    "name": testUserName
});

export const createTestOrg = async (orgName="Test organization") => {
    const checkOrg = await DBGetByValue(Organization,"name",orgName);
    if (checkOrg) {
        console.log(`createTestOrg existing. Found organization(s) with name ${orgName}`)
        return;
    }
    const newOrg = await DBSave(Organization, testOrg(orgName));
    console.log(`New organization created as ${orgName} id: ${newOrg.id}`);
    const newProj = await DBSave(Project, testProject(newOrg.id));
    console.log(`New project created for org ${orgName} project: ${newProj.name} id: ${newProj.id}`);
    await DBSave(User, testUser(newOrg.id, newProj.id))
    console.log(`New user created for org ${orgName} project: ${newProj.name} user id: ${newProj.id}`);
    // TODO Need to add defaultProjectID to organization
    // return await DBUpdate(organization, newOrg, {defaultProjectID: newProj.id});
    return newOrg;
}