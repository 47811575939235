import {Flex, Button, Heading, Tabs, TabItem} from "@aws-amplify/ui-react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const Component = ({children}) => {
    return children;
}

const EntityList = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const [pageWidth, setPageWidth] = useState("800px");

    const [showAll, setShowAll] = useState(false);
    const [onePage, setOnePage] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(props.currentItemId);

    const Entity = props.entity;
    const items = props.items;
    const parent = props.parent;
    const ListComponent = props.listComponent;
    const AddComponent = props.addComponent;
    const UpdateComponent = props.updateComponent;
    const onChangeHandler = props.onChangeHandler;
    const onRefreshHandler = props.onRefreshHandler;
    // const width = props.width; // || "100%";
    const maxWidthPct = props.maxWidthPct || 90;
    const resizeField = props.resizeField || 'text';
    const itemsPerPage = props.itemsPerPage || 6;
    // For TextArea, allow the textarea field to be resized by the user. Options are "both" or "none"
    const resize = props.resize || 'none';
    const adminUser = useSelector((state) => state.user.adminUser);

    const handleResize = () => {
        console.log(`EntityList - HandleResize: ${window.innerWidth}`)
        const width = window.innerWidth;
        const newWidth = Math.floor(width * maxWidthPct / 100);
        console.log(`EntityList - New Width: ${newWidth}px`);
        setPageWidth(`${newWidth}px`);
    };

    useEffect(() => {
        // console.log(`EntityList - Add event listner window resize: ${window.innerWidth}`)
        window.addEventListener('resize', handleResize);
        handleResize();
        if (selectedItemId) setActiveTab(1);
    },[]);

    const getParent = () => {
        if (!parent) return null;
        return {[`${parent.name}ID`]: parent.id};
    }
    const parentQueryCond = getParent();

    const handleUpdateSuccess = (item) => {
        console.log(item);
        setSelectedItemId(item.id);
        setActiveTab(0);
        onChangeHandler(item.id)
        // console.log(result);
    };

    const handleAddSuccess = (item) => {
        console.log("EntityList - handleAddSuccess item:");
        console.log(item);
        if (onRefreshHandler) onRefreshHandler();
        setActiveTab(0);
    };

    const handleSelectedItem = (item) => {
        if (!item.id) return;
        onChangeHandler(item.id);
        setSelectedItemId(item.id);
        setActiveTab(1);
    };

    // The generated update form expects the item to be passed in lower case of the Entity name.
    // Note: Resize field need to be refactored to be a list of fileds for form with multiple textarea fields.
    const updateParams = {
        id: selectedItemId,
        width: pageWidth,
        className: "entityForm",
        onSuccess: (item) => handleUpdateSuccess({id:selectedItemId, ...item}),
        onCancel: () => setActiveTab("List"),
        overrides: {
        [resizeField]: {
            resize: resize
        }
    }}

    // Pass in the fields in the form with the value of the parent id field that was passed in appended
    // Note: Resize field need to be refactored to be a list of fileds for form with multiple textarea fields.
    const addParams = {
        className: "entityForm",
        width: pageWidth,
        onSuccess: (item) => handleAddSuccess(item),
        onCancel:  () => setActiveTab("List"),
        onSubmit: (fields) => {
            const newFields = {...fields, ...parentQueryCond};
            console.log("EntityList - AddParams: ");
            console.log(newFields);
            return newFields;
        },
        overrides: {
            [resizeField]: {
                resize: resize
            },
        }
    }

    return (
        <Component>
            <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                width={pageWidth}
                marginTop={20}
            >
                <Heading level={4} marginTop={20}>{Entity.name}</Heading>
                <Tabs currentIndex={activeTab} onChange={(tab) => setActiveTab(tab)} spacing="equal">
                    <TabItem title="List" lable="List">
                        <Flex direction="column" alignItems="center" width={pageWidth}>
                        <ListComponent
                            className="collection"
                            width={pageWidth}
                            itemsPerPage={onePage ? 1000 : itemsPerPage}
                            items={showAll ? undefined : items}
                            overrideItems={({item, index}) => ({
                                sequence: `${index + 1}.`,
                                // TODO Need to refactor th is so the special added attribute can be done
                                // from the Higher Order component needing this (i.e. SummaryList)
                                // if this name overlaps with a different component attribute it will overwrite it.
                                summaryQuestion: item?.summaryQuestion,
                                width: "unset",
                                onClick: () => handleSelectedItem(item),
                             })
                            }
                        />
                        <Button
                            onClick={() => setOnePage(!onePage)}
                            marginTop={10}
                            fontSize="small"
                        >
                            {onePage ? 'Paginate' : `Single Page`}
                        </Button>
                        {(adminUser) &&
                            <Button
                                onClick={() => setShowAll(!showAll)}
                                marginTop={10}
                                fontSize="small"
                            >
                                {showAll ? 'Show Selected' : `Show All`}
                            </Button>
                        }
                        </Flex>
                    </TabItem>
                    <TabItem title="Update" label="Update">
                        <UpdateComponent {...updateParams}/>
                    </TabItem>
                    <TabItem title="Add" label="Add">
                        <AddComponent {...addParams}/>
                    </TabItem>
                </Tabs>
            </Flex>
        </Component>
    );
};

export default EntityList;