import {SelectField} from "@aws-amplify/ui-react";
import {useEffect, useState} from "react";
import {DBGetList} from "../utils/db";
import {Organization} from "../models";

const Dropdown = ({value, Entity, orgId, onChange, refresh}) => {
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState();

    // Fetch items from the database
    const fetchItems = async () => {
        try {
            const result = await DBGetList(Entity, Organization, orgId);
            result.sort((a, b) => a.name.localeCompare(b.name));
            setItems(result);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    // Fetch items when the component mounts
    useEffect(() => {
        fetchItems().then(() => console.log(`Dropdown: Found ${items.length} ${Entity}s`));
    }, [refresh]);

    useEffect(() => {
        setSelectedItem(value);
    },[value])

    // Handle drop-down selection change
    const handleChange = (event) => {
        setSelectedItem(event.target.value);
        onChange(event.target.value);
    };

    return (
        <SelectField
            size="large"
            label="Project"
            labelHidden
            value={selectedItem}
            onChange={handleChange}
            inputStyles={{
                textAlign: "center",
                fontWeight: "bold",
                border: "1px solid #95b3ef",
                whiteSpace: "normal",
                textOverflow: "unset",
                maxWidth: `calc(100% - 30px)`
            }}
        >
            <option value="">Select a project</option>
            {items.map((item) => (
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>
            ))}
        </SelectField>
    );
};

export default Dropdown;