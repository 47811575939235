import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AppHeader from "./components/AppHeader";
import {Flex} from "@aws-amplify/ui-react";
import AppMain from "./components/AppMain";
import OrganizationList from "./features/organization/OrganizationList";
import UserList from "./features/user/UserList";
import ProjectList from "./features/project/ProjectList";
import QuestionList from "./features/question/QuestionList";
import ResponseList from "./features/./response/ResponseList";
import { withAuthenticator } from '@aws-amplify/ui-react'
import {useEffect} from "react";
import {Hub} from "aws-amplify";
import {DBClear} from "./utils/db";
import SummaryList from "./features/summary/SummaryList";
import Export from "./features/export/Export";
import Import from "./features/export/Import";

import { Provider } from 'react-redux';
import store from "./store/store";

function App({signOut, user}) {

    useEffect(() => {
        console.log("App UseEffect");
        const authWatch = new Promise((resolve) => {
            console.log("Registering Hub.listen for auth");
            Hub.listen('auth', (data) => {
                const nextAuthState = data.payload.event;
                console.log(`handleStateChange: newstate: ${nextAuthState}`);
                console.log(data);
                switch (nextAuthState) {
                    case 'signOut':
                        console.log('user signed out');
                        DBClear();
                        resolve();
                        break
                    default:
                        break;
                }
            });
        });
        authWatch.then(() => console.log("user Signed Out - AuthWatch has ended"));
    }, []);

    return (
            <Provider store={store}>
                <Flex className="App-body">
                    <Router>
                        <AppHeader signOut={signOut} user={user}/>
                        <Routes>
                            <Route path="/" element={<AppMain signOut={signOut} user={user}/>}/>
                            <Route path="/summaries" element={<SummaryList/>}/>
                            <Route path="/organizations" element={<OrganizationList/>}/>
                            <Route path="/users" element={<UserList/>}/>
                            <Route path="/projects" element={<ProjectList/>}/>
                            <Route path="/questions" element={<QuestionList/>}/>
                            <Route path="/responses" element={<ResponseList/>}/>
                            <Route path="/export" element={<Export/>}/>
                            <Route path="/import" element={<Import/>}/>
                        </Routes>
                    </Router>
                </Flex>
            </Provider>
        );
}

export default withAuthenticator(App);
