import {Tabs, TabItem} from "@aws-amplify/ui-react";
import {useState, useEffect} from "react";
import {loginCurrentUser} from "../store/state";
import Interview from "../features/chat/Interview";
import Conversation from "../features/chat/Conversation";
import Summary from "../features/summary/Summary";

import {useSelector, useDispatch} from "react-redux";
import {setAuthenticated, setAdminUser, setCurrentUserId, setUserOrgId, setCurrentProjectId, setCurrentOrgId} from "../features/user/userSlice";
import {setInterviewProject, setInitialAnswer} from "../features/project/projectSlice";
import {setSummary, setCurrentResponseId} from "../features/summary/summarySlice";
import {setAnswerText, setQuestionText, setUserInitiated} from "../features/chat/chatSlice";
import Transcript from "../features/response/transcript";

const AppMain = ({user, signOut}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [pageWidth, setPageWidth] = useState(800);

    const dispatch = useDispatch();
    const {authenticated, currentUserId, currentProjectId } = useSelector((state) => state.user);
    const {userInitiated} = useSelector((state) => state.chat);
    const {interviewProject} = useSelector((state) => state.project);

    const handleResize = () => {
        console.log(`HandleResize: ${window.innerWidth}`)
        const width = window.innerWidth;
        const newWidth = Math.floor(width * 0.9);
        setPageWidth(newWidth);
    };

    const handleNavigation = (tab) => {
        setActiveTab(tab);
    }

    /*
        signIn: Emitted when a user successfully signs in.
        signUp: Emitted when a new user account is created.
        signOut: Emitted when a user successfully signs out.
        signIn_failure: Emitted when an error occurs during sign-in.
        signUp_failure: Emitted when an error occurs during sign-up.
        configured: Emitted when the Auth module configuration is completed.
        cognitoHostedUI: Emitted when using hosted UI.
        cognitoHostedUI_failure: Emitted when there is a failure in the hosted UI.
        parsingUrl_failure: Emitted when there is a failure parsing url.
        customOAuthState: Emitted when there is a custom OAuth state.
        tokenRefresh: Emitted when the token is refreshed.
        tokenRefresh_failure: Emitted when refreshing the token fails.
        codeFlow: Emitted when code flow is executed.
    */
    useEffect(() => {
        if (!user) return;
        console.log(`Appmain useEffect login: user`);
        console.log(user);
        if (!authenticated) {
            loginCurrentUser(user,
                (value) => dispatch(setCurrentUserId(value)),
                (value) => dispatch(setUserOrgId(value)),
                (value) => dispatch(setAdminUser(value)),
                (value) => dispatch(setCurrentOrgId(value)),
                (value) => dispatch(setCurrentProjectId(value)),
                (value) => dispatch(setInitialAnswer(value)),
                (value) => dispatch(setInterviewProject(value)),
                (value) => dispatch(setSummary(value)),
                (value) => dispatch(setAnswerText(value)),
                (value) => dispatch(setQuestionText(value)),
                (value) => dispatch(setCurrentResponseId(value)),
                (value) => dispatch(setUserInitiated(value))
            )
            .then((user) => {
                if (user) {
                    dispatch(setAuthenticated(true));
                    console.log("AppMain - useEffect - login initiated.");
                } else {
                    alert("Email not found. Please contact support.");
                    signOut();
                }

            })

        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
    })

    useEffect(() => {
        handleNavigation(0);
    }, [currentProjectId, userInitiated])


    if (!authenticated || !currentProjectId || !currentUserId) return;
    return (
        <Tabs currentIndex={activeTab} onChange={(tab) => setActiveTab(tab)} spacing="equal">
            {interviewProject &&
                <TabItem title={userInitiated ? "Help Chat" : "Interview"} label="Interview">
                    {userInitiated ?
                        <Conversation pageWidth={pageWidth}/> :
                        <Interview pageWidth={pageWidth} handleNavigation={() => handleNavigation(0)}/>
                    }
                </TabItem>
            }
            {!interviewProject &&
                <TabItem title="Project Chat" label="Project Chat">
                    <Conversation pageWidth={pageWidth}/>
                </TabItem>
            }
            <TabItem title="Transcript" label="Transcript">
                <Transcript pageWidth={pageWidth}/>
            </TabItem>
            {interviewProject &&
                <TabItem title="Summary" label="Summary">
                    <Summary pageWidth={pageWidth}/>
                </TabItem>
            }
        </Tabs>
    )
}

export default AppMain;