import ReactMarkdown from "react-markdown";
import {Text} from "@aws-amplify/ui-react";

const Markdown = ({children}) => {
    if (!children || !children.includes("```")) return children
    else return (
        <Text
            padding="10px"
            className="markdown-content">
            <ReactMarkdown>
                {children}
            </ReactMarkdown>
        </Text>
    )
}

export default Markdown;