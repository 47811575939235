// store.js
import {configureStore} from '@reduxjs/toolkit';
import rootReducer from "./rootReducer";

// Example of a custom logger middleware that can replace logger
// const customLoggerMiddleware = (store) => (next) => (action) => {
//     console.groupCollapsed(`[${new Date().toISOString()}] ACTION: ${action.type}`);
//
//     console.log('Prev state:', store.getState());
//     console.log('Action:', action);
//
//
//     console.log('Next state:', store.getState());
//     console.groupEnd();
//
//     return next(action);
// };

let middleware = [];

if (process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger');
    middleware.push(logger);
}

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(middleware),
});


export default store;