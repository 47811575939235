import Chat from "./Chat";
import {useEffect} from "react";
import {addQuestion, addResponse, addSummary, getMaxContext} from "../../store/state";
import {
    addContextInteraction,
    aiMessage,
    generateSummaryContext,
    tokenCount
} from "../../utils/openai";
import {streamer} from "../../utils/streamer";
import {checkForQuestion, answerQuestion} from "../../utils/aiLogic";

import {useDispatch, useSelector} from "react-redux";
import {setSummary, setUpdateSummary, setSummaryLoading,setCurrentResponseId} from "../summary/summarySlice";
import {
    setQuestionText,
    setAnswerText,
    setUserText,
    setCurrentQuestionId,
    setInterviewLoading,
    setInterviewTokens,
    setUserInitiated,
    setAssistantText
} from "./chatSlice";

const Interview = ({pageWidth, handleNavigation}) => {
    const dispatch = useDispatch();
    const {currentUserId, currentProjectId } = useSelector((state) => state.user);
    const {summary, currentResponseId} = useSelector((state) => state.summary);
    const {initialAnswer, interviewProject} = useSelector((state) => state.project);
    const {questionText, answerText, interviewLoading, interviewTokens, userInitiated}
        = useSelector((state) => state.chat);

    // Insure the child chat component is re-rendered on resize
    useEffect(() => {
        console.log(`Interview - useEffect PageWidth:`, pageWidth);
        let tokens = 0;
        getMaxContext(currentProjectId, summary, true)
            .then((history) => {
                tokens = tokenCount(JSON.stringify(history));
                dispatch(setInterviewTokens(tokens));
            })
        console.log(`Tokens: %{tokens}`);
     },[currentResponseId, userInitiated])

    const handleUpdateSummaryComplete = (text) => {
        setSummary(text);
        if (text) addSummary(currentProjectId, currentResponseId, text, (value) => dispatch(setSummary(value)))
            .then(result => console.log(`Interview - Summary - update complete - New summary: ${result.id}`));
    }

    const updateSummary = async () => {
        try {
            const context = await generateSummaryContext(currentProjectId, summary, interviewProject);
            const data = JSON.stringify(aiMessage(context, true));
            await streamer(data, (value) => dispatch(setSummary(value)), handleUpdateSummaryComplete);
        } catch (error) {
            console.error('Error starting streaming:', error);
            alert(error);
        }
    }

    const handleUserQuestion = async (history) => {
        const result = await answerQuestion(history,  questionText, answerText);
        const response = await addResponse(currentProjectId, currentUserId, true, null,
            answerText, result);
        console.log(`Handle Interview Question: User Question: ${answerText} Assistant Answer: ${response.questionText}`);
        dispatch(setUserInitiated(true));
        dispatch(setUserText(""));
        dispatch(setAssistantText(result));
        dispatch(setAnswerText(""));
        handleNavigation();
    }
    
    // Handle saving of Q&A store and manage interview/interaction state
    const handleSubmit = async () => {
        console.log("EntityList - Handle Submit");
        let history = await getMaxContext(currentProjectId, summary, true);
        if (answerText === initialAnswer) {
            // initial prompt so nothing to save
            dispatch(setAnswerText(""));
            console.log("AppMain - Skipping initial prompt place holder answer.");
            const history = await getMaxContext(currentProjectId, summary, true);
            return {
                history: history,
                send: true
            }
        } else if (await checkForQuestion(history, questionText, answerText)) {
            alert("Your response appears to be a question or request.\n" +
                "Switching to 'Need Help?' to handle your request.\n" +
                "My apologies if this is mistaken.");
            history = await getMaxContext(currentProjectId, summary, false);
            await handleUserQuestion(history);
            return {
                history: history,
                send: false
            }
        } else {
            // Assistant interview initiated interactions.
            // We can save the response if this is an interview question
            const question = await addQuestion(currentProjectId, questionText,
                (value) => dispatch(setCurrentQuestionId(value)));
            console.log("AppMain - Question Created", JSON.stringify(question));
            const response = await addResponse(currentProjectId, currentUserId, false, question.id,
                answerText, question.text, (resp) => {
                    if (!resp.userInitiated) dispatch(setCurrentResponseId(resp))
                });
            console.log("AppMain - response Created", JSON.stringify(response));
            addContextInteraction(history, questionText, answerText, false);
            dispatch(setUpdateSummary(true));
            dispatch(setSummaryLoading(true));
            updateSummary(response.id).then(() => {
                dispatch(setUpdateSummary(false));
                dispatch(setSummaryLoading(false));
            });
            return {
                history: history,
                send: true
            }
        }
    }

    return (
        <Chat
            pageWidth={pageWidth}
            handleSubmit={handleSubmit}
            userInitiated={userInitiated}
            setUserInitiated={(value) => dispatch(setUserInitiated(value))}
            interviewProject={interviewProject}
            loading={interviewLoading}
            setLoading={(value) => dispatch(setInterviewLoading(value))}
            questionText={questionText}
            setQuestionText={(value) => dispatch(setQuestionText(value))}
            answerText={answerText}
            setAnswerText={(value) => dispatch(setAnswerText(value))}
            tokens={interviewTokens}
            setTokens={(value) => dispatch(setInterviewTokens(value))}
        />
    )
}

export default Interview;