// projectSlice.js

import { createSlice } from '@reduxjs/toolkit';

const projectSlice = createSlice({
    name: 'project',
    initialState: {
        initialAnswer: "",
        interviewProject: true,
        refreshHeader: false,
    },
    reducers: {
        setInitialAnswer: (state, action) => { state.initialAnswer = action.payload; },
        setInterviewProject: (state, action) => { state.interviewProject = action.payload; },
        setRefreshHeader: (state, action) => { state.refreshHeader = action.payload; },
    },
});

export const { setInitialAnswer, setInterviewProject, setRefreshHeader } = projectSlice.actions;
export default projectSlice.reducer;