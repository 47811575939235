// summarySlice.js

import { createSlice } from '@reduxjs/toolkit';

const summarySlice = createSlice({
    name: 'summary',
    initialState: {
        summary: null,
        currentSummaryId: null,
        summaryLoading: false,
        updateSummary: false,
        currentResponseId: null,
        currentQuestionId: null,
    },
    reducers: {
        setSummary: (state, action) => { state.summary = action.payload; },
        setCurrentSummaryId: (state, action) => { state.currentSummaryId = action.payload; },
        setSummaryLoading: (state, action) => { state.summaryLoading = action.payload; },
        setUpdateSummary: (state, action) => { state.updateSummary = action.payload; },
        setCurrentResponseId: (state, action) => { state.currentResponseId = action.payload; },
        setCurrentQuestionId: (state, action) => { state.currentQuestionId = action.payload; },
    },
});

export const { setSummary, setCurrentSummaryId, setSummaryLoading, setUpdateSummary,
               setCurrentResponseId, setCurrentQuestionId } = summarySlice.actions;
export default summarySlice.reducer;