import {
    UserItemCollection,
    UserCreateForm,
    UserUpdateForm
} from "../../ui-components";
import {User} from "../../models";
import EntityList from "../../components/EntityList";
import {useEffect, useState} from "react";
import {fetchOrg} from "../../store/state";

import {setCurrentUserId} from "./userSlice";
import {setQuestionText} from "../chat/chatSlice";
import {useDispatch, useSelector} from "react-redux";

const UserList = () => {
    const [items, setItems] = useState();

    const dispatch = useDispatch();
    const {currentOrganizationId} = useSelector((state) => state.user);

    useEffect(() => {
        fetchData().then(() => console.log("UserList - useEffect - fetching users"));
    },[currentOrganizationId])

    const fetchData = async () => {
        const org = await fetchOrg(currentOrganizationId);
        const users = await org?.Users.toArray([]);
        setItems(users);
    }

    const handleOnChange = async (userId) => {
        console.log("HandleOnChange project");
        dispatch(setCurrentUserId(userId));
        console.log(`userId: ${userId} orgId: ${currentOrganizationId}`);
        setQuestionText("");
    }

    return (
        <EntityList
            parent = {{name: 'organization', id: currentOrganizationId}}
            entity = {User}
            items = {items}
            listComponent = {UserItemCollection}
            addComponent = {UserCreateForm}
            updateComponent = {UserUpdateForm}
            onChangeHandler = {handleOnChange}
            onRefreshHandler={ fetchData }
            selectable = {true}
        />
    )
}

export default UserList;
