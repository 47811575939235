import {
    createAssistantMessage,
    createUserMessage,
    createSystemMessage,
    sendMessage,
    truncateContext
} from "./openai";
import {gpt4_model} from "./openai";

// Check if the userText looks like a question rather than an answer
export const checkForQuestion = async (history, assistantQuestion, userText) => {
    history.push(createAssistantMessage(assistantQuestion));
    history.push(createUserMessage(userText));
    const systemPrompt = `Looking at the last user response only, please indicate if that user response is an answer to the previous assistants question (Answer) or a user prompt for information (Question). Respond with only one word, "Answer" or "Question" based on the user response. Do not provide any addition output or generated content.`;
    history.push(createSystemMessage(systemPrompt));
    const data = truncateContext(history);
    const result = await sendMessage("gpt-4", data, false)
    return result === "Question";
}

export const answerQuestion = async (history,  assistantText,  userText) => {
    history.push(createAssistantMessage(assistantText));
    history.push(createUserMessage(userText));
    return await sendMessage(gpt4_model,  history,  true);
}