import {
    ResponseItemCollection,
    ResponseCreateForm,
    ResponseUpdateForm
} from "../../ui-components";
import {Response} from "../../models";
import EntityList from "../../components/EntityList";
import {useEffect, useState} from "react";
import {fetchProject, sortedResponses} from "../../store/state";

import {useDispatch, useSelector} from "react-redux";
import {setCurrentResponseId} from "../summary/summarySlice";

const ResponseList = () => {
    const [items, setItems] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const dispatch = useDispatch();
    const {currentProjectId} = useSelector((state) => state.user);
    const {currentResponseId} = useSelector((state) => state.summary);

    const fetchData= async () => {
        const project = await fetchProject(currentProjectId);
        if (!project) setItems([]);
        else {
            console.log("ResponseList - useEffect - initItems");
            console.log(project);
            const responses = await sortedResponses(project);
            console.log(responses);
            setItems(responses);
        }
    }

    useEffect(() => {
        fetchData().then(() => console.log("ResponseList = useEffect - completed initItems"));
        setRefresh(false);
    },[currentProjectId, currentResponseId, refresh])

    const handleOnChange = async (responseId) => {
        dispatch(setCurrentResponseId(responseId));
        setRefresh(true);
    }

    return (
        <EntityList
            parent = {{name: 'project', id: currentProjectId}}
            entity = {Response}
            items = {items}
            listComponent = {ResponseItemCollection}
            addComponent = {ResponseCreateForm}
            updateComponent = {ResponseUpdateForm}
            onChangeHandler = {handleOnChange}
            onRefreshHandler={ fetchData }
            resize="both"
        />
    )
}

export default ResponseList;
