import {useEffect, useState} from "react";
import {projectExport} from "../../utils/projectExport";
import { useSelector } from 'react-redux';
import {Flex, Heading} from "@aws-amplify/ui-react";
import JSONViewer from 'react-json-view';

const Export = () => {
    const currentProjectId = useSelector((state) => state.user.currentProjectId);
    const [data, setData] = useState();
    const [pageWidth, setPageWidth] = useState("800px");

    const handleResize = () => {
        console.log(`EntityList - HandleResize: ${window.innerWidth}`)
        const width = window.innerWidth;
        const newWidth = Math.floor(width * 90 / 100);
        console.log(`EntityList - New Width: ${newWidth}px`);
        setPageWidth(`${newWidth}px`);
    };
    useEffect(() => {
       if (!currentProjectId) return;
       projectExport(currentProjectId)
            .then((result) => setData(result));
       handleResize();
    },[currentProjectId])

    return (
        <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            width={pageWidth}
            marginTop={20}
        >
            <Heading level={4} marginTop={20}>Project Export</Heading>
            <JSONViewer
                style={{fontFamily: "Inter",
                fontSize:"14px",
                fontWeight:"300"}}
                name="Project"
                enableClipboard
                collapsed={1}
                src={data}
            />
        </Flex>
    )
}

export default Export;