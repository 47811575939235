import {
    ProjectItemCollection,
    ProjectCreateForm,
    ProjectUpdateForm
} from "../../ui-components";
import {Project} from "../../models";
import EntityList from "../../components/EntityList";
import {useEffect, useState} from "react";
import {fetchOrg, initProjectChat} from "../../store/state";

import {useSelector, useDispatch} from "react-redux";
import {setCurrentProjectId} from "../user/userSlice";
import {setQuestionText, setAnswerText, setAssistantText, setUserText, setUserInitiated} from "../chat/chatSlice";
import {setSummary, setCurrentResponseId} from "../summary/summarySlice";
import {setInitialAnswer, setInterviewProject, setRefreshHeader} from "./projectSlice";


const ProjectList = () => {
    const [items, setItems] = useState([])
    const [refresh, setRefresh] = useState(false);

    const dispatch = useDispatch();
    const {currentProjectId, currentOrganizationId} = useSelector((state) => state.user);

    const fetchData = async () => {
        const org = await fetchOrg(currentOrganizationId);
        const projects = await org?.Projects.toArray();
        setItems(projects);
    }

    useEffect(() => {
        fetchData().then(() => console.log("ProjectList - useEffect - fetching projects"));
        setRefresh(false);
    },[refresh])

    const handleOnChange = async (projectId) => {
        console.log("HandleOnChange project");
        dispatch(setCurrentProjectId(projectId));
        await initProjectChat(
            projectId,
            (value) => dispatch(setInterviewProject(value)),
            (value) => dispatch(setInitialAnswer(value)),
            (value) => dispatch(setAssistantText(value)),
            (value) => dispatch(setUserText(value)),
            (value) => dispatch(setSummary(value)),
            (value) => dispatch(setQuestionText(value)),
            (value) => dispatch(setAnswerText(value)),
            (value) => dispatch(setCurrentResponseId(value)),
            (value) => dispatch(setRefreshHeader(value)),
            (value) => dispatch(setUserInitiated(value))
        ).then(() => setRefresh(true));
    }

    const handelOnSuccess = async () => {
        setRefresh(true);
        dispatch(setRefreshHeader(true));
    }

    return (
        <EntityList
            parent = {{name: 'organization', id: currentOrganizationId}}
            entity = {Project}
            items = {items}
            currentItemId = {currentProjectId}
            listComponent = {ProjectItemCollection}
            addComponent = {ProjectCreateForm}
            updateComponent = {ProjectUpdateForm}
            onChangeHandler = {handleOnChange}
            onRefreshHandler = {handelOnSuccess}
            selectable = {true}
            resizeField="base_prompt"
            resize="both"
        />
    )
}

export default ProjectList;
