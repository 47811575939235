// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Summary, Organization, User, Question, Response, Project } = initSchema(schema);

export {
  Summary,
  Organization,
  User,
  Question,
  Response,
  Project
};