import {useEffect} from "react";
import {addResponse, getMaxContext} from "../../store/state";
import {addContextInteraction, tokenCount} from "../../utils/openai";
import Chat from "./Chat";

import {useDispatch, useSelector} from "react-redux";
import {
    setUserText,
    setAssistantText,
    setChatTokens,
    setChatLoading,
    setUserInitiated
}
    from "./chatSlice";

const Conversation = ({pageWidth}) => {
    const dispatch = useDispatch();
    const {currentUserId, currentProjectId } = useSelector((state) => state.user);
    const {summary} = useSelector((state) => state.summary);
    const {interviewProject} = useSelector((state) => state.project);
    const {assistantText, userText, chatLoading, chatTokens, userInitiated} = useSelector((state) => state.chat);

    // Insure the child chat component is re-rendered on resize
    useEffect(() => {
        console.log(`Conversation - userEffect Pagewidth:`, pageWidth);
        getMaxContext(currentProjectId, summary, false).then((history) => {
            const tokens = tokenCount(JSON.stringify(history));
            dispatch(setChatTokens(tokens));
        })
    },[chatLoading])

    const handleResponse =  async (text) => {
        // prevent duplicate calls
        if (userText)
            await addResponse(currentProjectId, currentUserId, true, null, userText, text)
                .then(dispatch(setUserText(""))).then(setChatLoading(false));
    }

    // Handle saving of Q&A store and manage interview/interaction state
    const handleSubmit = async () => {
        console.log("EntityList - Handle Submit");
        // console.log("AppMain - State before answer:", state);
        // Associate the user input with the AI response if it's a question
        let history = await getMaxContext(currentProjectId, summary, false);
        addContextInteraction(history, assistantText, userText, false);
        return {
            history: history,
            send: true
        }
    }

    return (
        <Chat
            pageWidth={pageWidth}
            handleSubmit={handleSubmit}
            handleResponse={handleResponse}
            interviewProject={interviewProject}
            userInitiated={userInitiated}
            setUserInitiated={(value) => dispatch(setUserInitiated(value))}
            loading={chatLoading}
            setLoading={(value) => dispatch(setChatLoading(value))}
            questionText={assistantText}
            setQuestionText={(value) => dispatch(setAssistantText(value))}
            answerText={userText}
            setAnswerText={(value) => dispatch(setUserText(value))}
            tokens={chatTokens}
            setTokens={(value) => dispatch(setChatTokens(value))}
        />
    )
}

export default Conversation;