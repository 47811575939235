// userSlice.js

import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        interviewLoading: false,
        chatLoading: false,
        questionText: "",
        assistantText: "",
        answerText: "",
        userText: "",
        currentQuestionId: null,
        userInitiated: false,
        interviewTokens: 0,
        chatTokens: 0
    },
    reducers: {
        setInterviewLoading: (state, action) => { state.interviewLoading = action.payload; },
        setChatLoading: (state, action) => { state.chatLoading = action.payload; },
        setQuestionText: (state, action) => { state.questionText = action.payload; },
        setAssistantText: (state, action) => { state.assistantText = action.payload; },
        setAnswerText: (state, action) => { state.answerText = action.payload },
        setUserText: (state, action)=> { state.userText = action.payload; },
        setCurrentQuestionId: (state, action)=> { state.currentQuestionId = action.payload; },
        setUserInitiated: (state, action)=> { state.userInitiated = action.payload; },
        setInterviewTokens: (state, action)=> { state.interviewTokens = action.payload; },
        setChatTokens: (state, action)=> { state.chatTokens = action.payload; }
    }
});

export const { setInterviewLoading, setChatLoading, setQuestionText,
               setAssistantText, setAnswerText, setUserText, setUserInitiated,
               setInterviewTokens, setCurrentQuestionId, setChatTokens} = chatSlice.actions;
export default chatSlice.reducer;