import {DBGet, DBSave, DBUpdate} from "./db";
import {Project, Question, Response, Summary} from "../models";
import {sortBySeqAndUpdatedAt} from "../store/state";

export const projectExport = async (projectId) => {
    const project = await DBGet(Project, projectId);
    const responses = await project.Responses.toArray();
    responses.sort(sortBySeqAndUpdatedAt);
    const responsesJson = responses.map((r) => ({
        id: r.id,
        sequence: r.sequence,
        questionID: r.questionID,
        text: r.text,
        questionText: r.questionText,
        userInitiated: r.userInitiated
    }));
    const summaries = await project.Summaries.toArray();
    const summariesJson = summaries.map((s) => ({
        id: s.id,
        responseID: s.responseID,
        text: s.text
    }));
    const questions = await project.Questions.toArray();
    const questionsJson = questions.map((q) => ({
        id: q.id,
        text: q.text
    }));
    const projectJson = {
        base_prompt: project.base_prompt,
        base_prompt_intro: project.base_prompt_intro,
        currentSummary: project.currentSummary,
        description: project.description,
        initial_answer_prompt: project.initial_answer_prompt,
        name: project.name,
        organizationID: "<organizationId>",
        summary_prompt: project.summary_prompt,
        summary_template: project.summary_template,
        current_summary: project.currentSummary,
        userInitiated: project.userInitiated,
        Responses: responsesJson,
        Summaries: summariesJson,
        Questions: questionsJson,
    };
    console.log(`Project Export: ${JSON.stringify(projectJson)}`);
    return projectJson;
}

export const projectImport = async (data, name, projectId, orgId, userId) => {
    const json = JSON.parse(data);
    let projectJson = {
        base_prompt: json.base_prompt,
        base_prompt_intro: json.base_prompt_intro,
        currentSummary: json.currentSummary,
        description: json.description,
        initial_answer_prompt: json.initial_answer_prompt,
        name: name,
        organizationID: orgId,
        summary_prompt: json.summary_prompt,
        summary_template: json.summary_template,
        userInitiated: json.userInitiated,
     };
    const newProject = await DBSave(Project, projectJson);

    // Set up a map for later population of new question ids for the duplicated ones.
    const questionIds = json.Questions.map((q) => q.id);
    const questionMap = questionIds.reduce((acc, value) => {
        acc[value] = null;
        return acc;
    }, new Map());

    // Add question to project
    for (const q of json.Questions) {
        const newQuestion = await DBSave(Question, {
            projectID: newProject.id,
            text: q.text
        // For each question created, save the old and new id in a map for response creation.
        });
        questionMap[q.id] = newQuestion.id;
    }

    // Set up a map for later population of new question ids for the duplicated ones.
    const responseIds = json.Responses.map((r) => r.id);
    const responseMap = responseIds.reduce((acc, value) => {
        acc[value] = null;
        return acc;
    }, new Map());

    for (const r of json.Responses) {
        const newResponse = await DBSave(Response, {
            userID: userId,
            projectID: newProject.id,
            sequence: r.sequence,
            questionID: questionMap[r.questionID],
            text: r.text,
            questionText: r.questionText,
            userInitiated: r.userInitiated
        });
        responseMap[r.id] = newResponse.id;
    }

    // Set up a map for later population of new question ids for the duplicated ones.
    const summaryIds = json.Summaries.map((r) => r.id);
    const summaryMap = summaryIds.reduce((acc, value) => {
        acc[value] = null;
        return acc;
    }, new Map());

    for (const s of json.Summaries) {
        const newSummary = await DBSave(Summary, {
            projectID: newProject.id,
            text: s.text,
            responseID: responseMap[s.responseID]
        });
        summaryMap[s.id] = newSummary.id;
    }
    const updates = (s) => {s.currentSummary = summaryMap[json.currentSummary]}
    const project = await DBUpdate(Project, newProject, updates);
    projectJson = {
        base_prompt: project.base_prompt,
        base_prompt_intro: project.base_prompt_intro,
        currentSummary: project.currentSummary,
        description: project.description,
        initial_answer_prompt: project.initial_answer_prompt,
        name: project.name,
        organizationID: "<organizationId>",
        summary_prompt: project.summary_prompt,
        summary_template: project.summary_template,
        current_summary: project.currentSummary,
        userInitiated: project.userInitiated,
        Responses: await project.Responses.toArray(),
        Summaries: await project.Summaries.toArray(),
        Questions: await project.Questions.toArray()
    };
    return projectJson;
}