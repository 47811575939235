import {
    SummaryItemCollection,
    SummaryCreateForm,
    SummaryUpdateForm
} from "../../ui-components";
import {Response, Summary} from "../../models";
import EntityList from "../../components/EntityList";
import {useEffect, useState} from "react";
import {fetchProject, sortedSummaries} from "../../store/state";
import {DBGet} from "../../utils/db";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentSummaryId} from "./summarySlice";

const SummaryList = () => {
    const [items, setItems] = useState([])

    const dispatch = useDispatch();
    const {currentProjectId} = useSelector((state) => state.user);

    const fetchData = async () => {
        const project = await fetchProject(currentProjectId);
        if (!project) setItems([])
        else {
            const summaries = await sortedSummaries(project);
            const summaryItems = [];
            for (const s of summaries) {
                const response = await DBGet(Response, s.responseID);
                summaryItems.push({...s, summaryQuestion: response?.questionText});
            }
            console.log("SummaryList - summaryItems");
            console.log(summaryItems);
            setItems(summaryItems);
        }
    }

    useEffect(() => {
        fetchData().then(() => console.log("SummaryList - fetching Summaries"));
    },[currentProjectId])


    const handleOnChange = async (summaryId) => {
        dispatch(setCurrentSummaryId(summaryId));
    }

    return (
        <EntityList
            parent = {{name: 'project', id: currentProjectId}}
            entity = {Summary}
            items = {items}
            itemsPerPage = {1}
            listComponent = {SummaryItemCollection}
            addComponent = {SummaryCreateForm}
            updateComponent = {SummaryUpdateForm}
            onChangeHandler = {handleOnChange}
            onRefreshHandler={ fetchData }
            resize="both"
        />
    )
}

export default SummaryList;
